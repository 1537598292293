var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"customer-tables","fluid":"","tag":"section"}},[_c('ConfirmDialog',{attrs:{"isShow":_vm.confirmData.isConfirm,"title":_vm.confirmData.confirm_title,"message":_vm.confirmData.confirm_msg,"selectItem":_vm.confirmData.selectItem,"selectAction":_vm.confirmData.selectAction},on:{"onClose":_vm.onClose,"onConfirm":_vm.onConfirm}}),_c('InfoDialog',{attrs:{"isShow":_vm.infoData.isConfirm,"title":_vm.infoData.info_title,"message":_vm.infoData.info_msg},on:{"onClose":_vm.onClose}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"Customer Table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"multi-sort":"","show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":12}},[_c('schedule-table',{attrs:{"isHideExpand":true,"customer_mission_id":item.id}})],1)]}},{key:"item.create_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.create_time,"YYYY-MM-DD HH:mm:ss")))]}},{key:"item.update_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.update_time,"YYYY-MM-DD HH:mm:ss")))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("getText")(item.status,_vm.customer_status)))]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateActive(item.id, item.is_active)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmStartSchedule(item)}}},on),[_vm._v("mdi-new-box")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("create")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.exportFullTP(item)}}},on),[_vm._v("mdi-database-export")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("export")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.exportCreditorOnlyTP(item)}}},on),[_vm._v("mdi-file-export")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("export")))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }