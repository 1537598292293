<template>
  <v-container id="customer-tables" fluid tag="section">
    <ConfirmDialog
      :isShow="confirmData.isConfirm"
      :title="confirmData.confirm_title"
      :message="confirmData.confirm_msg"
      :selectItem="confirmData.selectItem"
      :selectAction="confirmData.selectAction"
      v-on:onClose="onClose"
      v-on:onConfirm="onConfirm"
    ></ConfirmDialog>
    <InfoDialog
      :isShow="infoData.isConfirm"
      :title="infoData.info_title"
      :message="infoData.info_msg"
      v-on:onClose="onClose"
    >
    </InfoDialog>

    <base-material-card
      icon="mdi-clipboard-text"
      title="Customer Table"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
        show-expand
      >
        <template v-slot:expanded-item="{ item }">
          <td :colspan="12">
            <schedule-table
              :isHideExpand="true"
              :customer_mission_id="item.id"
            ></schedule-table>
          </td>
        </template>
        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.status="{ item }">{{
          item.status | getText(customer_status)
        }}</template>
        <template v-slot:item.is_active="{ item }">
          <v-switch
            v-model="item.is_active"
            @change="updateActive(item.id, item.is_active)"
          ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="confirmStartSchedule(item)"
                >mdi-new-box</v-icon
              >
            </template>
            <span>{{ $t("create") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="exportFullTP(item)"
                >mdi-database-export</v-icon
              >
            </template>
            <span>{{ $t("export") }}</span>
          </v-tooltip>
          <!-- 有設定 -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="exportCreditorOnlyTP(item)"
                >mdi-file-export</v-icon
              >
            </template>
            <span>{{ $t("export") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { customer_status, action_type_enum } from "@/definition.js";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import InfoDialog from "../dialogs/InfoDialog";
import ScheduleTable from "./ScheduleTable";
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    items: [],
    totalItems: 0,
    spider_process: [],
    spider_process_count: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("name"),
        value: "name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("lbtype"),
        value: "lbtype",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },

      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    customer_status: customer_status,
    confirmData: {
      isConfirm: false,
      confirm_title: "啟動新任務",
      confirm_msg: "",
      selectItem: null,
      selectAction: 0,
    },
    infoData: {
      isConfirm: false,
      info_title: "訊息",
      info_msg: "",
    },
  }),
  components: {
    ScheduleTable,
    ConfirmDialog,
    InfoDialog,
  },
  computed: {
    ...mapState(["token"]),
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  methods: {
    getApi() {
      console.log("get spider list ");
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/customers/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },

    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    createSchedule(item) {
      // create new schedule

      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/customers/" +
        item.id +
        "/createSchedule/";
      let jsonData = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log(jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    confirmStartSchedule(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = action_type_enum.Mixed;
      this.confirmData.confirm_msg = `啟動任務: ${item.name}`;
      this.confirmData.isConfirm = true;
      console.log(`mission type ${this.confirmData.selectAction}`);
    },
    onClose() {
      // reset confirm
      this.confirmData.isConfirm = false;
      this.confirmData.confirm_msg = "";
      this.confirmData.selectAction = 0;
      this.confirmData.selectItem = null;
      // reset info
      this.infoData.isConfirm = false;
      this.infoData.info_title = "";
      this.infoData.info_msg = "";
    },
    onConfirm(data) {
      console.log("start new schedule.", data);
      let _item = data.item;
      let _action = data.action;
      console.log(`Get action ${_action}`);
      switch (_action) {
        case action_type_enum.Mixed:
          console.log("create new schedule ");
          this.createSchedule(_item);
          break;
        case action_type_enum.FullTranscript:
          console.log("create full tp ");
          this.createReport(_item, _action);
          break;
        case action_type_enum.GetPrivateSetting:
          console.log("create private setting tp ");
          this.createReport(_item, _action);
          break;
      }
      this.onClose();
    },
    // export the full tp
    exportFullTP(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = action_type_enum.FullTranscript;
      this.confirmData.confirm_msg = `匯出任務: ${item.name}`;
      this.confirmData.isConfirm = true;
      console.log(`mission type ${this.confirmData.selectAction}`);
    },
    exportCreditorOnlyTP(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = action_type_enum.GetPrivateSetting;
      this.confirmData.confirm_msg = `匯出設定任務: ${item.name}`;
      this.confirmData.isConfirm = true;
      console.log(`mission type ${this.confirmData.selectAction}`);
    },
    createReport(item, action) {
      console.log(`create repot ${item.id}`);
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/backend/customers/${item.id}/genreport/`;
      // process.env.VUE_APP_SERVER_URL + "/backend/schedules/" + item.id + "/r";
      let jsonData = {
        // status: item.status,
        action: action,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.showAlert(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    showAlert(data) {
      console.log("check the data", data);
      if (data.status == 0) {
        console.log("show msg", data.msg);
        this.infoData.info_msg = `訊息: ${data.msg}`;
        this.infoData.isConfirm = true;
      } else {
        this.infoData.info_msg = `訊息: ${data.msg}`;
        this.infoData.isConfirm = true;
      }
    },
  },
};
</script>
